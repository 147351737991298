<template>
  <div class="enquiry">
    <div class="container">
      <div class="title">Dataplan Enquiry</div>
      <div class="search col-12">
        <van-search
          v-model="value"
          show-action
          placeholder="Enter ICCID"
          @search="onSearch"
        >
          <template #action>
            <div style="width: 50px;">
              <van-button @click="onSearch" :loading="loading" type="primary" round size="large" color="#0071EB" icon="search"></van-button>
            </div>
          </template>
        </van-search>
      </div>
      <div class="order-card col-12" v-if="planList.length">
        <van-card v-for="(item,index) in planList" :key="index">
        <template #title>
          <div class="card-title">{{item.plan_name}}</div>
        </template>
        <template #desc>
          <div class="card-desc">Time zone in Hong Kong (GMT+8)</div>
          <div class="card-desc">Activation Time：{{item.active_at==0?'-':item.active_at}}</div>
          <div class="card-desc">Expiration Time：{{item.expire_at==0?'-':item.expire_at}}</div>
          <div class="card-desc">Terminate Time：{{item.end_at==0?'-':item.end_at}}</div>
          <div class="card-desc" v-if="item.usage||item.usage==0">Usage (MB)：{{item.usage}}</div>
        </template>
      </van-card>
      </div>
      <div class="question">
        <van-collapse v-model="activeNames" :border="false">
          <van-collapse-item :title="item.title" :name="index" v-for="(item,index) in questionList" :key="index">
            {{ item.content }}
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import MD5 from "md5";
import { message } from 'ant-design-vue'
import { GYGEnquiryIccidAPI } from '../../api/api'
export default {
  data() {
    return {
      value: "",
      loading:false,
      planList:[],
      activeNames: ['1'],
      questionList:[
        {
          title:'how to evaluate whether cell-phone can be workable for esim？',
          content:'Enter phone call page, input “*#06#”, Displaying EID means support .'
        },
        {
          title:'Quick to check?',
          content:'a.Please install and activate esim successfully b. Turn on esim mobile data and data roaming (Please select our esim as defult line)'
        },
        {
          title:'How to find activation code in Getyourguide?',
          content:'Please go to booking information, you will find the QR activation code. If you just have a phone, please contact us to send the enter code information.'
        },
        {
          title:'When should I install and active my esim?',
          content:"Please go to the airport to install and active it, if you install the esim in advance, it may will lose days the usage valid times. If you're already at your destination, you can do it right away."
        },
        {
          title:'How to install and activate an esim on the IOS and Android?',
          content:'Here is a video how to use esim: https://www.youtube.com/watch?v=daBkrkO7qaU(Remark: The IOS enter code information format is 2 lines, the Android may be 1 lines)'
        },
        {
          title:'When install the esim, why it show invalid?',
          content:'It may the esim has been installed in your phone successfully, the esim just can be scanned a time. Please go to sim manager to check it, our esim doesn’t include phone number.'
        },
        {
          title:'Why my network suddenly get slow or stop working? ',
          content:'Your plan starts the moment you activate your eSIM by scanning the QR code on your smartphone.'
        },
        {
          title:'Why the network speed get slow?',
          content:'It may your phone has used all high speed data or the coverage of local network is not good. Please restart your phone and wait for 3 minutes.'
        },
        {
          title:'If you follow all instructions but still doesn’t work, please contact CS service to technical support',
          content:'(Whatsapp:85251961035 /email: reservation@triproaming.com)'
        },
        {
          title:'When does my data plan start?',
          content:'Your plan starts the moment you activate your eSIM by scanning the QR code on your smartphone.'
        },
        {
          title:'Which devices work with the Travel eSIM ?',
          content:'Most of the devices produced from 2020 are compatible with the Travel eSIM, including, but not limited to: Apple iPhone SE (2020), XR, XS, XS Max, iPhone 11 series, 12 series, 13 series, 14 series,  Samsung S20, S20+, S20 Ultra, Samsung S21 models, Samsung S22 models, Galaxy Z Flip, Galaxy Z fold, Note 20 models, Fold LTE, , Huawei P40, P40 Pro, Google Pixel 3, 3a, 4, 4a, 5, 6, 7 and Motorola Razr 2019. Some iPad models are also compatible (Pro 11 and 12,9 inches 3rd generation, iPad Air 3rd generation, 7th generation, and Mini 5th generation), and the Apple Watch Series 4. Make sure your smartphone is unlocked (i.e. not tied to a specified mobile provider).'
        },
        {
          title:'Why do I need Wifi to activate the eSIM?',
          content:'You need get your phone connected to wifi during installation of the eSIM because the phone has to download the eSIM’s profile by scanning the QR code.'
        },
        {
          title:'Can I make phone calls and send messages with my Travel eSIM?',
          content:'Some eSIM only allow you to use mobile data, some provide you both mobile dataI and phone calls and text messages. Check carefully the description of each eSIM’s package.'
        },
        {
          title:'How quick is the Travel eSIM?',
          content:'Our Travel eSIM offers maximum speed coverage (4G/ LTE). But bear in mind that in some areas of limited coverage there may be a lower speed connection. Most of our eSIM plans offer unlimited low speed at 128kbps, 256kbps, or 384kbps, so you can enjoy unlimited internet accesss even when you finish the maximum speed data.'
        },
        {
          title:'Do I have to activate data roaming on my device?',
          content:'Yes, you must turn on data roaming of your Travel eSIM on your mobile settings.'
        },
        {
          title:'Should I delete and re-install the eSIM when there’s no 4G?',
          content:'No, you must not delete the eSIM once it’s been installed successfully.  eSIMs can only be installed successfully one time. You should check your phone settings and make sure it’s done correctly following our instructions. You can also contact our 24/7 customer support team at reservation@triproaming.com or our online chat function.'
        },
        {
          title:'Can I use my SIM card and Travel eSIM at the same time?',
          content:'Yes, you can use your SIM card and your Travel eSim at the same time. Choose the Sim card for phone calls and SMS, and Travel eSIM for data from your device. Please remember that if you leave your Sim card activated, your network provider may apply data roaming charges to receive or make phone calls as well as SMS.'
        },
        {
          title:'Can I renew or extend the data on my eSIM?',
          content:'At the moment, you cannot renew the data on your Travel eSIM. However, you can purchase another eSIM if you need more data.'
        },
        {
          title:'How many times can I use my eSIM card?',
          content:'Your eSIM can only be activated on one device. If you delete the eSIM from your device, you will not be able to reuse it. You cannot scan the QR code either on two devices or two times on one device.'
        },
        {
          title:'Which eSIM carrier will I connect to?',
          content:'The eSIM will connect to the best available network in your destination. So, depending on which area you are in, you might see a certain network. This network may be switched when you move to another area. You can see the list of available networks of each eSIM in its description.'
        },
      ]
    };
  },
  methods: {
    async onSearch() {
      if(!this.value) return
      if (this.value&&this.value.length < 6) {
        message.warning('he length of the card number is invalid')
        return
      }
      try {
        this.loading = true
        const params = this.GETSIGN({iccid:this.value.replace(/\s+/g, '')})
        const { data } = await GYGEnquiryIccidAPI(params)
        this.loading = false 
        this.planList = data
        if(data.length<=0) message.warning('Empty')
      } catch (error) {
        this.loading = false 
      }
    },
    GETSIGN(obj) {
      obj = {
        nonce_str: Math.random().toString(36).substring(2, 8),
        timestamp: Date.parse( new Date() ).toString().substring(0,10),
        ...obj
      };
      let keySort = Object.keys(obj).sort();
      let str = keySort.filter(key => obj[key] || obj[key] === 0)
      .map(key => `${key}${encodeURIComponent(obj[key])}`).join('');
      str += '5s@*8fa&0';
      obj.sign = MD5(str).toUpperCase();
      return obj;
    },
  },
};
</script>
<style lang="scss" scoped>
.enquiry {
  padding: 45px 0;
  background: linear-gradient(to bottom,#FF5533 0%,#FF5533 20%,#EBEEF1 20%,#EBEEF1 100%);
  .title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .search{
    padding: 10px;
    border-radius: 16px;
    background-color: #fff;
    /deep/ .van-search__content{
      background-color: #fff;
      .van-cell{
        font-size: 18px;
        .van-field__left-icon{
          .van-icon-search{
            font-size: 18px;
          }
        }
      }
    }
    /deep/ .van-search__action{
      line-height: 0;
      &:active{
        background-color: #fff;
      }
    }
  }
  .order-card{
    margin-top: 30px;
    padding: 20px;
    border-radius: 16px;
    background-color: #fff;
    /deep/ .van-card{
      background-color: #fff;
    }
    .card-title{
      word-break:break-all;
      font-size: 16px;
      font-weight: bold;
      color:#333;
    }
    .card-desc{
      font-size: 14px;
      line-height: 36px;
      color:#333;
    }
  }
  .question{
    margin-top: 30px;
    padding: 10px;
    border-radius: 16px;
    background-color: #fff;

  }
}
</style>
